import { useContext } from 'react';
import { AppContext } from '../Contexts/AppContext';

export default function useProducts() {
    const context = useContext(AppContext);

    return {
        products: context.products,
    };
}
