import { createContext } from 'react';

// Products
import imgProduct01 from '../asset/images/products/product01.png';
import imgProduct02 from '../asset/images/products/product02.png';
import imgProduct03 from '../asset/images/products/product03.png';
import imgProduct04 from '../asset/images/products/product04.png';

// Skills
import html from '../asset/images/skills/html.png';
import css from '../asset/images/skills/css.png';
import js from '../asset/images/skills/js.png';
import sass from '../asset/images/skills/sass.png';
import nodejs from '../asset/images/skills/nodejs.png';
import reactjs from '../asset/images/skills/reactjs.png';
import php from '../asset/images/skills/php.png';
import mysql from '../asset/images/skills/mysql.png';
import restfullapi from '../asset/images/skills/restfullapi.png';
import json from '../asset/images/skills/json.png';
import github from '../asset/images/skills/github.png';
import vscode from '../asset/images/skills/vscode.png';

// CV
import cv from '../asset/cv/CV_FullstackWebDeveloper_LeTuanKiet.pdf';

import { GithubIcon, FacebookIcon, LinkedinIcon, EmailIcon, CVIcon } from '../components/Icons';

export const AppContext = createContext({});

export default function AppProvider({ children }) {
    const info = {
        location: 'Le Van Viet Street, Thu Duc Municipal City, Ho Chi Minh City, Vietnam.',
        email: 'Py.ltk1998@gmail.com',
        numberPhone: '0379722235',
        social_network: [
            {
                icon: <FacebookIcon />,
                label: 'Facebook',
                link: 'https://www.facebook.com/Py.1409',
            },
            {
                icon: <GithubIcon />,
                label: 'Github',
                link: 'https://github.com/20424101-LeTuanKiet',
            },
            {
                icon: <LinkedinIcon />,
                label: 'Linkedin',
                link: 'https://www.linkedin.com/in/letuankiet',
            },
            {
                icon: <EmailIcon />,
                label: 'Email',
                link: 'mailto:Py.ltk1998@gmail.com',
            },
            {
                icon: <CVIcon />,
                label: 'Download CV',
                link: cv,
            },
        ],
    };

    const products = [
        {
            id: 1,
            name: 'Website Personal Trainer',
            photo: imgProduct01,
            describe: 'Online calculation tool and blog sharing knowledge about Gym & Fitness.',
            tech_fe: '',
            tech_be: '',
            technicals: 'HTML5, CSS3, Javascript, jQuery, PHP MVC, Ajax.',
            demo: 'https://ptgym.online',
            frontend: '',
        },
        {
            id: 2,
            name: 'Chat Group App',
            photo: imgProduct02,
            describe: 'App creates groups and realtime chat, uses firebase to store data.',
            tech_fe: '',
            tech_be: '',
            technicals: 'Reactjs (hooks, ant design, sass module), Firebase.',
            demo: 'https://chat-group.click',
            frontend: 'https://github.com/20424101-LeTuanKiet/chat',
        },
        {
            id: 3,
            name: 'Music Player App',
            photo: imgProduct04,
            describe: 'Mini App to listen to music online, has dark-mode and saves previous music listening status.',
            tech_fe: '',
            tech_be: '',
            technicals: 'Reactjs (hooks, useContext, sass module), LocalStorage.',
            demo: 'https://20424101-letuankiet.github.io/music-player',
            frontend: 'https://github.com/20424101-LeTuanKiet/music-player',
        },
        {
            id: 4,
            name: 'Todo App',
            photo: imgProduct03,
            describe: 'The app notes to-do tasks, supports search, priority and filtering.',
            tech_fe: 'Reactjs (hooks, axios, ant design, redux toolkit), LocalStorage.',
            tech_be: 'RESTfulAPI NodeJS & Express, API Logger, API Security (accessToken, refreshToken), MySQL.',
            technicals: 'Using ReactJS and NodeJS RestfullAPI Express',
            demo: 'https://main--joyful-douhua-a63162.netlify.app',
            frontend: 'https://github.com/20424101-LeTuanKiet/todo-app-redux-and-redux-toolkit',
            backend: 'https://github.com/20424101-LeTuanKiet/todo_app_api',
            flag_have_BE: true,
        },
    ];

    const skills = [
        {
            id: 1,
            name_1: 'HTML5',
            photo_1: html,
            name_2: 'CSS3',
            photo_2: css,
        },
        {
            id: 2,
            name_1: 'JavaScript',
            photo_1: js,
            name_2: 'SASS',
            photo_2: sass,
        },
        {
            id: 3,
            name_1: 'NodeJS',
            photo_1: nodejs,
            name_2: 'ReactJS',
            photo_2: reactjs,
        },
        {
            id: 4,
            name_1: 'RESTfull API',
            photo_1: restfullapi,
            name_2: 'JSON',
            photo_2: json,
        },
        {
            id: 5,
            name_1: 'PHP',
            photo_1: php,
            name_2: 'MySQL',
            photo_2: mysql,
        },
        {
            id: 6,
            name_1: 'Github',
            photo_1: github,
            name_2: 'VS Code',
            photo_2: vscode,
        },
    ];
    return <AppContext.Provider value={{ info, products, skills }}>{children}</AppContext.Provider>;
}
