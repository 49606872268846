import classNames from 'classnames/bind';
import style from './Info.module.scss';
import { useEffect, useState } from 'react';

import background from '../../asset/images/parallax/background.png';
import tree from '../../asset/images/parallax/tree.png';
import owl01 from '../../asset/images/parallax/owl01.png';
import owl02 from '../../asset/images/parallax/owl02.png';
import house from '../../asset/images/parallax/house.png';
import moon from '../../asset/images/parallax/moon.png';
import ghost01 from '../../asset/images/parallax/ghost01.png';
import ghost02 from '../../asset/images/parallax/ghost02.png';
import witch from '../../asset/images/parallax/witch.png';
import land from '../../asset/images/parallax/land.png';
import { forwardRef } from 'react';
import About from '../About';

const cx = classNames.bind(style);

function Info(props, ref) {
    useEffect(() => {
        let text = document.getElementById('text');
        let moon = document.getElementById('moon');
        let tree = document.getElementById('tree');
        let owl = document.getElementById('owl');
        let witch = document.getElementById('witch');
        let house = document.getElementById('house');
        let ghost01 = document.getElementById('ghost01');
        let ghost02 = document.getElementById('ghost02');

        const handleScrollParallax = () => {
            let value = window.scrollY;
            const sh = window.screen.height;
            if (value < sh) {
                text.style.marginTop = value * 2 + 'px';
                moon.style.marginTop = value * 0.8 + 'px';
                tree.style.marginLeft = value * -1 + 'px';
                owl.style.marginLeft = value * -1 + 'px';
                witch.style.marginLeft = value * -3 + 'px';
                house.style.marginLeft = value * 1 + 'px';
                ghost01.style.marginLeft = value * -1.2 + 'px';
                ghost02.style.marginLeft = value * 0.7 + 'px';
            }
        };

        window.addEventListener('scroll', handleScrollParallax);

        return () => window.removeEventListener('scroll', handleScrollParallax);
    }, []);

    const [owlEffect, setOwlEffect] = useState(1);

    useEffect(() => {
        const handleScrollParallax = () => {
            let value = window.scrollY;
            const sh = window.screen.height;
            if (value > sh) {
                setOwlEffect(0);
            } else {
                setOwlEffect(1);
            }
        };

        window.addEventListener('scroll', handleScrollParallax);

        let owl = document.getElementById('owl');

        const timer = setTimeout(() => {
            switch (owlEffect) {
                case 1:
                    owl.src = owl02;
                    setOwlEffect(2);
                    break;
                case 2:
                    owl.src = owl01;
                    setOwlEffect(1);
                    break;
                default:
                    break;
            }
        }, 800);

        return () => {
            clearTimeout(timer);
            window.removeEventListener('scroll', handleScrollParallax);
        };
    }, [owlEffect]);

    const [showJobs, setShowJobs] = useState(false);

    useEffect(() => {
        const observer = (id, el) => {
            const ob = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    switch (id) {
                        case 'observer-jobs':
                            if (entry.isIntersecting) {
                                setShowJobs(true);
                                ob.unobserve(entry.target);
                            }
                            break;

                        default:
                            break;
                    }
                });
            });

            ob.observe(el);
        };

        const elements = document.querySelectorAll('.observer-jobs');
        elements.forEach((el) => observer('observer-jobs', el));
    }, []);

    return (
        <section className={cx('wrapper')}>
            <section className={cx('parallax')}>
                <img src={background} alt="background" id="background" />
                <img src={moon} alt="moon" id="moon" />
                <img src={tree} alt="tree" id="tree" />
                <img src={owl01} alt="owl" id="owl" />
                <img src={witch} alt="witch" id="witch" />

                <div id="text" className={style.text}>
                    <p className={cx('name')}>I am Le Tuan Kiet </p>
                    <div className={cx('jobs', 'observer-jobs', 'hide', showJobs && 'show')}>
                        <span className={cx('first-text')}>I am a</span>
                        <ul className={cx('sec-texts')}>
                            <li>
                                <span>Developer</span>
                            </li>
                            <li>
                                <span>Freelancer</span>
                            </li>
                            <li>
                                <span>Personal Trainer</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <img src={land} alt="land" id="land" />
                <img src={house} alt="house" id="house" />
                <img src={ghost01} alt="ghost01" id="ghost01" />
                <img src={ghost02} alt="ghost02" id="ghost02" />
            </section>

            <section ref={ref} className={cx('sec')}>
                <About />
            </section>
        </section>
    );
}

const forwardedInfo = forwardRef(Info);
export default forwardedInfo;
