import { useContext } from 'react';
import { AppContext } from '../Contexts/AppContext';

export default function useInfo() {
    const context = useContext(AppContext);

    return {
        info: context.info,
    };
}
