import classNames from 'classnames/bind';
import style from './Loading.module.scss';

import avatar from '../../asset/images/avatar.jpg';
import { useEffect, useState } from 'react';

const cx = classNames.bind(style);

export default function Loading({ children }) {
    const [loading, setLoading] = useState(false);
    const [loadingEffect, setLoadingEffect] = useState(false);

    useEffect(() => {
        if (!loading) {
            const colorPalettes = [
                ['F6BDC0', 'F1959B', 'F07470', 'EA4C46', 'DC1C13'],
                ['FDB777', 'FDA766', 'FD9346', 'FD7F2C', 'FF6200'],
                ['FFFFB7', 'FFF192', 'FFEA61', 'FFDD3C', 'FFD400'],
                ['C5E8B7', 'ABE098', '83D475', '57C84D', '2EB62C'],
                ['CCF9FF', '7CE8FF', '55D0FF', '00ACDF', '0080BF'],
                ['B589D6', '9969C7', '804FB3', '6A359C', '552586'],
            ];

            function getColorPalettes(colorPalettes, item, index) {
                return colorPalettes[index][item - 1];
            }

            let circularProgress = document.querySelector('#circle-progress');
            let progressStartValue = 0;
            let progressEndValue = 5;
            let speed = 80;
            const indexColor = Math.floor(Math.random() * colorPalettes.length);

            const progress = setInterval(() => {
                progressStartValue++;
                circularProgress.style.background = `conic-gradient(#${getColorPalettes(
                    colorPalettes,
                    progressStartValue,
                    indexColor,
                )} ${progressStartValue * 72}deg, #eeedef 0deg)`;

                if (progressStartValue === progressEndValue) {
                    setLoadingEffect(true);

                    const timer = setTimeout(() => {
                        setLoading(true);
                        clearTimeout(timer);
                    }, 1000);

                    clearInterval(progress);
                }
            }, speed);
        }
    }, [loading]);

    return (
        <section id="loading" className={cx('wrapper', loadingEffect && 'hidden')}>
            <div id="circle-progress" className={cx('circle-progress')}>
                <img className={cx('progress-img')} src={avatar} alt="avatar-loading" />
            </div>
        </section>
    );
}
