import classNames from 'classnames/bind';
import style from './About.module.scss';
import avatar from '../../asset/images/avatar-about.png';
import useInfo from '../../hooks/useInfo';
import { useEffect, useState } from 'react';

// import bg_img from '../../asset/images/bg-about.jpg';

const cx = classNames.bind(style);

export default function About() {
    const { info } = useInfo();

    const contact = [...info.social_network];
    contact.pop();

    const [showIcon, setShowIcon] = useState(false);

    useEffect(() => {
        const observer = (id, el) => {
            const ob = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    switch (id) {
                        case 'observer-icon':
                            if (entry.isIntersecting) {
                                setShowIcon(true);
                                // ob.unobserve(entry.target);
                            } else {
                                setShowIcon(false);
                            }
                            break;

                        default:
                            break;
                    }
                });
            });

            ob.observe(el);
        };

        const elements = document.querySelectorAll('.observer-icon');
        elements.forEach((el) => observer('observer-icon', el));
    }, []);

    return (
        <div className={cx('wrapper')}>
            <div className={cx('box-avatar')}>
                <img className={cx('avatar')} src={avatar} alt="" />
                <div className={cx('about-connect')}>
                    {contact.map((item, index) => (
                        <a
                            className={cx('social', `observer-icon`, 'hide', showIcon && 'show')}
                            key={index}
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className={cx('about-connect-item')} data-name={item.label}>
                                <span className={cx('about-connect-item-icon')}>{item.icon}</span>
                            </div>
                        </a>
                    ))}
                </div>
            </div>

            <div className={cx('box-about')}>
                <div className={cx('about-name')}>Hello, my name is Le Tuan Kiet.</div>
                <div className={cx('about-describe')}>
                    As a software developer, my expertise is to create software that gives the best and most optimal
                    user experience. I specialize in using cutting-edge technologies to create high-quality web
                    applications.
                </div>
            </div>
        </div>
    );
}
