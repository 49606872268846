import { useContext } from 'react';
import { AppContext } from '../Contexts/AppContext';

export default function useSkills() {
    const context = useContext(AppContext);

    return {
        skills: context.skills,
    };
}
