import classNames from 'classnames/bind';
import style from './Products.module.scss';

import { GithubIcon, EarthAsiaIcon } from '../Icons';
import useProducts from '../../hooks/useProducts';
import { forwardRef, useEffect, useState } from 'react';

const cx = classNames.bind(style);

function Products(props, ref) {
    const { products } = useProducts();

    const [showProducts, setShowProducts] = useState(false);

    useEffect(() => {
        const observer = (id, el) => {
            const ob = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    switch (id) {
                        case 'observer-products':
                            if (entry.isIntersecting) {
                                setShowProducts(true);
                                ob.unobserve(entry.target);
                            }
                            break;

                        default:
                            break;
                    }
                });
            });

            ob.observe(el);
        };

        const elements = document.querySelectorAll('.observer-products');
        elements.forEach((el) => observer('observer-products', el));
    }, []);

    return (
        <section ref={ref} className={cx('wrapper')}>
            <h2 className={cx('label')}>Products</h2>
            <label className={cx('title')}>Look at My Products.</label>

            <div className={cx('products-wrapper')}>
                {products.toReversed().map((product) => (
                    <div
                        key={product.id}
                        className={cx('product', 'observer-products', 'hide', showProducts && 'show')}
                    >
                        <img className={cx('product-image')} src={product.photo} alt="" />
                        <div className={cx('product-info')}>
                            <p className={cx('product-info-name')}>{product.name}</p>
                            <div className={cx('product-info-technicals')}>{product.technicals}</div>
                        </div>
                        <div className={cx('product-btn')}>
                            <p className={cx('product-des')}>{product.describe}</p>
                            {product.tech_fe && (
                                <p className={cx('product-des-tech')}>
                                    <span className={cx('product-des-tech-title')}>Frontend:</span>
                                    <span> {product.tech_fe}</span>
                                </p>
                            )}
                            {product.tech_be && (
                                <p className={cx('product-des-tech')}>
                                    <span className={cx('product-des-tech-title')}>Backend:</span>
                                    <span> {product.tech_be}</span>
                                </p>
                            )}
                            <div className={cx('btn')}>
                                {product.demo && (
                                    <a href={product.demo} target="_blank" rel="noopener noreferrer">
                                        <button className={cx('btn-demo')}>
                                            <EarthAsiaIcon /> Live demo
                                        </button>
                                    </a>
                                )}
                                {product.frontend && (
                                    <a href={product.frontend} target="_blank" rel="noopener noreferrer">
                                        <button className={cx('btn-github')}>
                                            <GithubIcon />
                                            Github {product.flag_have_BE && '[Frontend]'}
                                        </button>
                                    </a>
                                )}
                                {product.backend && (
                                    <a href={product.backend} target="_blank" rel="noopener noreferrer">
                                        <button className={cx('btn-github')}>
                                            <GithubIcon />
                                            Github [Backend]
                                        </button>
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

const forwardedProducts = forwardRef(Products);
export default forwardedProducts;
