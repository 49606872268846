import classNames from 'classnames/bind';
import style from './Contact.module.scss';
import useInfo from '../../hooks/useInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMapPin,
    faMessage,
    faMobileScreen,
    faPaperPlane,
    faSpinner,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark, faCircleCheck, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { forwardRef, useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import img_pumpkin from '../../asset/images/contacts/pumpkin.png';
import img_witch from '../../asset/images/contacts/witch.png';

const cx = classNames.bind(style);

function Contact(props, ref) {
    const { info } = useInfo();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [emailValidation, setEmailValidation] = useState(true);

    const [submitForm, setSubmitForm] = useState(false);
    const [stateSendEmail, setStateSendEmail] = useState('');

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        setSubmitForm(true);
        emailjs.sendForm('service_rk42umd', 'template_jtoprx8', form.current, 'CmhkgaR0nvTql3P1B').then(
            (result) => {
                setStateSendEmail('success');
                setSubmitForm(false);
            },
            (error) => {
                setStateSendEmail('error');
                setSubmitForm(false);
            },
        );

        setName('');
        setEmail('');
        setMessage('');

        const user_message = document.getElementById('user_message');
        user_message.value = '';
    };

    const okSubmitForm = () => {
        setSubmitForm(false);
        setStateSendEmail('');
    };

    const [showInput, setShowInput] = useState(false);

    useEffect(() => {
        const observer = (id, el) => {
            const ob = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    switch (id) {
                        case 'observer-input':
                            if (entry.isIntersecting) {
                                setShowInput(true);
                                ob.unobserve(entry.target);
                            }
                            break;

                        default:
                            break;
                    }
                });
            });

            ob.observe(el);
        };

        const elements = document.querySelectorAll('.observer-input');
        elements.forEach((el) => observer('observer-input', el));
    }, []);

    return (
        <section ref={ref} className={cx('wrapper')}>
            <div className={cx('box-contact')}>
                <img className={cx('img-witch')} src={img_witch} alt="witch" />
                <img className={cx('img-pumpkin')} src={img_pumpkin} alt="pumpkin" />
                <h2 className={cx('label')}>Say Hi 👋🏻</h2>
                <label className={cx('title')}>Let's Work Together.</label>
                <p className={cx('idioms')}>Teamwork makes common people achieve uncommon results.</p>
                <div className={cx('contact')}>
                    <div className={cx('personal-information')}>
                        <div className={cx('info')}>
                            <div className={cx('icon')}>
                                <FontAwesomeIcon icon={faMapPin} />
                            </div>
                            <p className={cx('info-content')}>{info.location}</p>
                        </div>
                        <div className={cx('info')}>
                            <div className={cx('icon')}>
                                <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <a className={cx('info-content')} href={'mailto:' + info.email}>
                                {info.email}
                            </a>
                        </div>
                        <div className={cx('info')}>
                            <div className={cx('icon')}>
                                <FontAwesomeIcon icon={faMobileScreen} />
                            </div>
                            <a className={cx('info-content')} href={'tel:' + info.numberPhone}>
                                {info.numberPhone}
                            </a>
                        </div>
                    </div>
                    <div className={cx('get-in-touch')}>
                        <label className={cx('contact-title')}>Get In Touch</label>
                        <form className={cx('form')} ref={form} onSubmit={sendEmail}>
                            <div className={cx('contact-form')}>
                                <div className={cx('input', 'observer-input', 'hide', showInput && 'show')}>
                                    <div className={cx('input-control')}>
                                        <input
                                            type="text"
                                            value={name}
                                            placeholder="Name"
                                            name="user_name"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setName(e.target.value.trim());
                                            }}
                                        />
                                    </div>
                                    <div className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                </div>
                                <div className={cx('input', 'observer-input', 'hide', showInput && 'show')}>
                                    <div className={cx('input-control', !emailValidation && 'input-invalid')}>
                                        <input
                                            type="text"
                                            value={email}
                                            placeholder="Email"
                                            name="user_email"
                                            autoComplete="off"
                                            onChange={(e) => {
                                                setEmail(e.target.value.trim());
                                                if (!validateEmail(e.target.value.trim())) {
                                                    setEmailValidation(false);
                                                } else {
                                                    setEmailValidation(true);
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className={cx('input-icon', !emailValidation && 'icon-invalid')}>
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </div>
                                    {!emailValidation && <div className={cx('error')}>Invalid email!</div>}
                                </div>
                                <div className={cx('input', 'observer-input', 'hide', showInput && 'show')}>
                                    <div className={cx('input-control')}>
                                        <textarea
                                            rows={4}
                                            placeholder="Message"
                                            id="user_message"
                                            name="user_message"
                                            autoComplete="off"
                                            defaultValue={message}
                                            onChange={(e) => {
                                                setMessage(e.target.value.trim());
                                            }}
                                        >
                                            {/* {message} */}
                                        </textarea>
                                    </div>
                                    <div className={cx('input-icon')}>
                                        <FontAwesomeIcon icon={faMessage} />
                                    </div>
                                </div>

                                <div
                                    className={cx(
                                        'submit',
                                        name && email && message && emailValidation && 'show-submit',
                                    )}
                                >
                                    <FontAwesomeIcon style={{ marginRight: '7px' }} icon={faPaperPlane} />
                                    <input className={cx('submit-input')} type="submit" value="Let's Talk" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={cx('box-connect')}>
                    <h2 className={cx('box-connect-label')}>Let's Connect 🤝🏻</h2>
                    <div className={cx('box-connect-container')}>
                        {info.social_network.map((item, index) => (
                            <a key={index} href={item.link} target="_blank" rel="noopener noreferrer">
                                <div className={cx('box-connect-item')} data-name={item.label}>
                                    <span className={cx('box-connect-item-icon')}>{item.icon}</span>
                                    <span className={cx('box-connect-item-label')}>{item.label}</span>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>

            {submitForm && (
                <div className={cx('wrapper-popup')}>
                    <div className={cx('submit-popup')}>
                        <FontAwesomeIcon icon={faSpinner} />
                    </div>
                </div>
            )}

            {stateSendEmail && (
                <div className={cx('wrapper-popup')}>
                    {stateSendEmail === 'success' ? (
                        <div className={cx('popup')}>
                            <div className={cx('popup-icon', 'success-icon')}>
                                <FontAwesomeIcon icon={faCircleCheck} />
                            </div>
                            <div className={cx('popup-title')}>Success!</div>
                            <div className={cx('popup-message')}>
                                <p>Thanks for contacting me.</p>
                                <p>I will respond to you via email as soon as I can.</p>
                            </div>
                            <div onClick={okSubmitForm} className={cx('popup-btn', 'btn-success')}>
                                OK
                            </div>
                        </div>
                    ) : (
                        <div className={cx('popup')}>
                            <div className={cx('popup-icon', 'error-icon')}>
                                <FontAwesomeIcon icon={faCircleXmark} />
                            </div>
                            <div className={cx('popup-title')}>Oops!</div>
                            <div className={cx('popup-message')}>
                                <p>There's something wrong.</p>
                                <p>Please contact us through other channels.</p>
                            </div>
                            <div onClick={okSubmitForm} className={cx('popup-btn', 'btn-error')}>
                                OK
                            </div>
                        </div>
                    )}
                </div>
            )}
        </section>
    );
}

const forwardedContact = forwardRef(Contact);
export default forwardedContact;
