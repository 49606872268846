import Loading from './components/Loading';
import Header from './components/Header';
import Info from './components/Info';
import Skills from './components/Skills';
import Products from './components/Products';
import Footer from './components/Footer';
import AppProvider from './Contexts/AppContext';
import Contact from './components/Contact';
import { useRef } from 'react';

function App() {
    const aboutRef = useRef(null);
    const executeScrollAboutRef = () => aboutRef.current.scrollIntoView();

    const skillRef = useRef(null);
    const executeScrollSkillRef = () => skillRef.current.scrollIntoView();

    const productRef = useRef(null);
    const executeScrollProductRef = () => productRef.current.scrollIntoView();

    const contactRef = useRef(null);
    const executeScrollContactRef = () => contactRef.current.scrollIntoView();
    return (
        <AppProvider>
            <Loading />
            <Header
                handleAboutRef={executeScrollAboutRef}
                handleSkillRef={executeScrollSkillRef}
                handleProductRef={executeScrollProductRef}
                handleContactRef={executeScrollContactRef}
            />
            <Info ref={aboutRef} />
            <Skills ref={skillRef} />
            <Products ref={productRef} />
            <Contact ref={contactRef} />
            <Footer />
        </AppProvider>
    );
}

export default App;
