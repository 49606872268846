import React from 'react';
import classNames from 'classnames/bind';
import style from './Footer.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

const cx = classNames.bind(style);

export default function Footer() {
    return (
        <section className={cx('wrapper')}>
            <h2 className={cx('idioms')}>
                If you fall asleep now, you will dream. If you study now, you will live your dream.
            </h2>
            <div className={cx('hr')}></div>
            <div className={cx('end')}>
                <div className={cx('copyright')}>
                    Copyright <FontAwesomeIcon icon={faCopyright} /> 2023, Powered by Le Tuan Kiet
                </div>
            </div>
        </section>
    );
}
