import { Swiper, SwiperSlide } from 'swiper/react';
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import classNames from 'classnames/bind';
import style from './Skills.module.scss';
import './custom.scss';
import useSkills from '../../hooks/useSkills';
import { forwardRef, useEffect, useState } from 'react';

const cx = classNames.bind(style);

function Skills(props, ref) {
    const { skills } = useSkills();

    const [showSkill, setShowSkill] = useState(false);

    const [showTitleSkill, setShowTitleSkill] = useState(false);

    useEffect(() => {
        const observer = (id, el) => {
            const ob = new IntersectionObserver((entries) => {
                entries.forEach((entry) => {
                    switch (id) {
                        case 'skills':
                            if (entry.isIntersecting) {
                                setShowSkill(true);
                                ob.unobserve(entry.target);
                            }
                            // else {
                            //     setShowSkill(false);
                            // }
                            break;

                        case 'title-skills':
                            if (entry.isIntersecting) {
                                setShowTitleSkill(true);
                                ob.unobserve(entry.target);
                            }
                            break;

                        default:
                            break;
                    }
                });
            });

            ob.observe(el);
        };

        const elements = document.querySelectorAll('#skills');
        elements.forEach((el) => observer('skills', el));

        const elements2 = document.querySelectorAll('#title-skills');
        elements2.forEach((el) => observer('title-skills', el));
    }, []);

    return (
        <section ref={ref} className={cx('wrapper')}>
            <h2 id="title-skills" className={cx('title', 'hide-title', showTitleSkill && 'show-title')}>
                Skills
            </h2>

            <div id="skills" className={cx('swiper-wrapper', 'hide', showSkill && 'show')}>
                <Swiper
                    slidesPerView={2}
                    speed={500}
                    spaceBetween={20}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2000,
                        pauseOnMouseEnter: true,
                    }}
                    breakpoints={{
                        950: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                        },
                        1280: {
                            slidesPerView: 4,
                            spaceBetween: 30,
                        },
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                >
                    {skills.map((skill) => (
                        <SwiperSlide key={skill.id}>
                            <div className={cx('card-wrapper')}>
                                <div className={cx('card')}>
                                    <img src={skill.photo_1} alt={skill.name_1} className={cx('card-img')} />
                                    <div className={cx('card-name')}>{skill.name_1}</div>
                                </div>

                                <div className={cx('card')}>
                                    <img src={skill.photo_2} alt={skill.name_2} className={cx('card-img')} />
                                    <div className={cx('card-name')}>{skill.name_2}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}

const forwardedSkills = forwardRef(Skills);
export default forwardedSkills;
