import classNames from 'classnames/bind';

import style from './Header.module.scss';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesUp, faBarsStaggered, faXmark } from '@fortawesome/free-solid-svg-icons';
import cv from '../../asset/cv/CV_FullstackWebDeveloper_LeTuanKiet.pdf';

const cx = classNames.bind(style);

export default function Header({ handleAboutRef, handleSkillRef, handleProductRef, handleContactRef }) {
    const [nav, setNav] = useState(false);

    const [visibleBackToTop, setVisibleBackToTop] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    };

    // hide, show back-to-top btn on scroll
    useEffect(() => {
        const handleBackToTop = () => {
            let value = window.scrollY;
            const sh = window.screen.height;
            if (value > sh && !visibleBackToTop && !nav) {
                setVisibleBackToTop(!visibleBackToTop);
            } else {
                if (value < sh && visibleBackToTop) {
                    setVisibleBackToTop(!visibleBackToTop);
                }
            }
        };

        window.addEventListener('scroll', handleBackToTop);

        return () => window.removeEventListener('scroll', handleBackToTop);
    }, [visibleBackToTop, nav]);

    // hide, show navigation on scroll
    useEffect(() => {
        const header = document.getElementById('header');

        const handleHeader = () => {
            let value = window.scrollY;
            const sh = window.screen.height;
            if (value > sh) {
                header.style.top = '0';
                header.style.opacity = '1';
                // header.style.background = 'var(--background-secondary)';
            } else {
                if (nav === false) {
                    header.style.top = '-100px';
                    header.style.opacity = '0';
                    // header.style.background = 'transparent';
                }
            }
        };

        window.addEventListener('scroll', handleHeader);

        return () => window.removeEventListener('scroll', handleHeader);
    }, [nav]);

    const handleMenu = () => {
        setNav(!nav);
        setVisibleBackToTop(!visibleBackToTop);
    };

    const handleClickMenu = (page = 'home') => {
        setNav(!nav);

        switch (page) {
            case 'about':
                handleAboutRef();
                break;
            case 'skills':
                handleSkillRef();
                break;
            case 'products':
                handleProductRef();
                break;
            case 'contact':
                handleContactRef();
                break;
            case 'cv':
                window.location.href = cv;
                break;
            default:
                scrollToTop();
                break;
        }
    };

    return (
        <header id="header">
            <h2 className={cx('logo')}>Logo</h2>
            <nav className={cx('navigation', nav && 'mobile-menu')}>
                <ul>
                    <li>
                        <button onClick={handleClickMenu}>Home</button>
                    </li>
                    <li>
                        <button onClick={() => handleClickMenu('about')}>About</button>
                    </li>
                    <li>
                        <button onClick={() => handleClickMenu('skills')}>Skills</button>
                    </li>
                    <li>
                        <button onClick={() => handleClickMenu('products')}>Products</button>
                    </li>
                    <li>
                        <button onClick={() => handleClickMenu('contact')}>Contact</button>
                    </li>
                    <li>
                        <button onClick={() => handleClickMenu('cv')}>CV</button>
                    </li>
                </ul>
            </nav>
            {!nav ? (
                <div className={cx('menu')} onClick={handleMenu}>
                    <FontAwesomeIcon className={cx('font-aws')} icon={faBarsStaggered} />
                </div>
            ) : (
                <div className={cx('menu', 'menu-close')} onClick={handleMenu}>
                    <FontAwesomeIcon className={cx('font-aws')} icon={faXmark} />
                </div>
            )}

            {/* Back to top */}
            <button
                onClick={scrollToTop}
                className={cx('back-to-top', visibleBackToTop && 'back-to-top-show')}
                id="back-to-top"
            >
                <FontAwesomeIcon icon={faAnglesUp} />
            </button>
        </header>
    );
}
